<template>
  <v-card class="shadow academy_card pointer" style="border-radius: 8px" @click="viewEvent">
    <v-card-text class="relative">
      <v-menu
          absolute
          content-class="q-menu"
          right
          top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              :ripple="false"
              absolute
              class="text_capitalize "
              elevation="0"
              right
              style="background-color: transparent; min-width: fit-content !important; height: fit-content !important; padding: 2px !important; top:10px "
              top
              v-bind="attrs"
              v-on="on"

          >
            <DotsIcon/>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="checkWritePermission($modules.workshops.management.slug)"
              @click.stop="duplicateWorkshop"
          >
            <SvgIcon
                :text="toggleStatus === 'completed' ? 'Create New' : 'Duplicate' "
                class="font-medium text-sm gap-x-2"
            >
              <template #icon>
                <CopyIcon/>
              </template>
            </SvgIcon>
          </v-list-item>
          <v-list-item
              v-if=" checkWritePermission($modules.workshops.management.slug) && (status_id == 1 || status_id == 11)"
              @click="editWorkshop"
          >
            <SvgIcon class="font-medium text-sm gap-x-2" text="Edit">
              <template #icon>
                <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
              </template>
            </SvgIcon>
          </v-list-item>
          <v-divider class="mb-2"/>
          <v-list-item v-if="checkDeletePermission($modules.workshops.management.slug) && status_id != 12"
                       @click="cancelWorkshop">
            <SvgIcon :class="{'red--text svg-stroke-red':status_id === 1}"
                     :text="(status_id === 1 ? 'Cancel' : 'Reactivate')" class="font-medium text-sm gap-x-2">
              <template #icon>
                <ActivateIcon/>
              </template>
            </SvgIcon>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-row class="border-bottom pb-2" dense>
        <v-col cols="12">
          <div class="d-flex gap-x-2">
            <div style="width: 155px; height: 155px">
              <view-image
                  :defaultImage="'workshop'"
                  :height="155"
                  :image="image_path"
                  :width="155"
                  style="border-radius: 8px"
              ></view-image>
            </div>
            <div class="ml-2" style="width: 100%">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <p class="text-lg black-text font-bold line-clamp-1 p-0 m-0"
                     style="max-width: 80%"
                     v-bind="attrs"
                     v-on="on">
                    {{ name }}
                  </p>
                </template>
                <span>{{ name }}</span>
              </v-tooltip>
              <p class="text-neon font-normal mb-1">
                {{
                  typeof workshop_type != "undefined" ? workshop_type : "-"
                }} |
                {{
                  typeof venue_service != "undefined" ? venue_service : "-"
                }}
              </p>
              <p class="m-0 p-0 text-sm font-normal ">Start Date</p>
              <p class="m-0 p-0 black-text text-base">{{ start_date | dateformat }}</p>
              <p class="m-0 mt-2 p-0 text-sm font-normal ">End Date</p>
              <p class="m-0 p-0 black-text text text-base">{{ end_date | dateformat }}</p>
            </div>


          </div>
        </v-col>


      </v-row>

      <v-row class="mt-4" dense>
        <v-col class="text-center" cols="6">
          <div class="promos_stats">
            <p class="text-base font-normal text-dark-gray ">Programs</p>
            <p class="text-base blue-text font-medium ">
              {{ Number(total_programs || 0) | numberFormatter }}
            </p>
          </div>

        </v-col>
        <v-col class="text-center" cols="6">
          <div class="promos_stats ">
            <p class="text-base font-normal text-dark-gray">Trainers:</p>
            <p class="text-base black-text font-medium">
              {{ Number(trainers || 0) }}
            </p>
          </div>
        </v-col>


        <v-col class="text-center" cols="6">
          <div class="promos_stats ">
            <p class="text-base font-normal text-dark-gray">Students:</p>
            <p class="text-base black-text font-medium text-elepsiss">

              {{ Number(participants || 0) | numberFormatter }}

            </p>
          </div>
        </v-col>
        <v-col
        v-if="checkReadPermission($modules.workshops.sales.slug)" class="text-center"
               cols="6"
        >
          <div class="promos_stats ">
            <p class="text-base font-normal text-dark-gray">Sales:</p>
            <p class="text-base black-text font-medium"> {{ Number(sales || 0) | toCurrency }} </p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <!-- <v-card color="#3e4c55" class="mt-2 mr-2 ml-2" v-if="1==2">
    <v-card-text>
      <v-row class="pr-2 pl-2">
        <v-col cols="12" md="5">
          <v-card flat tile class="d-flex">
            <view-image
              :image="image_path"
              :height="100"
              defaultImage="workshop"
            ></view-image>
          </v-card>
        </v-col>
        <v-col cols="12" md="7">
          <div class="venue_details">
            <h3 class="grey--text text--lighten-5">
              <span
                class="d-inline-block text-truncate"
                style="max-width: 85%"
                >{{ name }}</span
              >
            </h3>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="
                    position: absolute;
                    margin: auto;
                    right: 11px;
                    top: 0;
                    height: 38px;
                    width: 38px;
                  "
                  fab
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="mt-2 ml-2"
                  @click="duplicateWorkshop"
                  ><v-icon color="info">mdi-content-duplicate</v-icon></v-btn
                >
              </template>
              <span
                >{{ toggleStatus == "completed" ? "Create New" : "Duplicate" }}
              </span>
            </v-tooltip>

          </div>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card> -->
</template>

<script>
import CopyIcon from "@/assets/images/misc/copy-icon.svg";
import EditIcon from "@/assets/images/tables/edit.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DotsIcon from "@/assets/images/misc/h-options.svg";
import ActivateIcon from "@/assets/images/partners/activate.svg";

export default {
  components: {
    ActivateIcon, DotsIcon, SvgIcon, EditIcon,
    CopyIcon
  },
  props: {
    description: {type: String, default: ""},
    start_date: {type: String, default: ""},
    end_date: {type: String, default: ""},
    venue_service: {type: String},
    id: {type: Number},
    status_id: {type: Number},
    image_path: {type: String, default: null},
    location: {type: String, default: ""},
    name: {type: String, default: ""},
    total_programs: {type: Number, default: 0},
    trainers: {type: Number, default: 0},
    participants: {type: Number, default: 0},
    sales: {type: Number, default: 0},
    workshop_type: {type: String, default: ""},
    toggleStatus: {type: String, default: ""},
  },

  methods: {
    editEvent() {
      this.$router.push({
        name: "WorkshopEdit",
        params: {data: btoa(this.id)},
      });
    },
    deleteEvent() {
      this.$emit("delete", {date: this.date, id: this.slug});
    },

    viewEvent() {

      this.$router.push({
        name: "WorkshopView",
        params: {data: btoa(JSON.stringify({id: this.id}))},
      });
    },
    cancelWorkshop() {
      this.$emit("cancel", this.id);
    },
    duplicateWorkshop() {
      if (this.toggleStatus == "completed") {
        this.editEvent();
      } else {
        this.$emit("duplicate", this.id);
      }
    },
    editWorkshop() {
      this.$router.push({
        name: "WorkshopEdit",
        params: {data: btoa(this.id)},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.promos_stats {
  p {
    margin: 0;
    padding: 0;
  }
}

.workshop-text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 60%;
  overflow: hidden;
}


.academy_card {
  border: 1px solid rgba(17, 42, 70, 0);
  cursor: pointer;
}

.academy_card:hover {
  border: 1px solid rgba(17, 42, 70, 0.5);
  box-shadow: 0 8px 24px 0 rgba(70, 76, 136, 0.20) !important;

}
</style>
