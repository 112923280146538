<template>
  <v-dialog :value="viewConfig" width="1000" @input="close" scrollable>
    <v-card>
      <v-card-title class="headline">Configurations</v-card-title>
      <v-card-text class="pa-4">
        <v-row dense>
          <GeneralMembership
              :note=true
              note-text="Note: This will apply on all new memberships by default"
              :existingMembershipData="benefit_workshop"
              @updateForm="updateFormWithMembership"
          />
        </v-row>
        <v-card rounded outlined class="pa-4 mt-2">

          <v-col lg="4" md="4">
            <v-checkbox
              v-model="configuration.tnc_enable"
              class="mt-2"
              false-value="0"
              true-value="1"
              label="Enable Website T&C's"
            ></v-checkbox>
          </v-col>
          <v-row
            class="mt-10 mb-10"
            style="border: 1px solid black;"
            v-if="configuration.tnc_enable == 1"
          >
            <v-card style="width: 100%; min-width: 300px">
              <h2 class="pa-4">Terms and Conditions</h2>
              <div
                class="px-4 pb-4 d-flex flex-row flex-wrap"
                style="width: 100%"
              >
                <text-editor
                  @complete="setTncContent"
                  :message="configuration.tnc"
                  style="width: 100%"
                />
              </div>
            </v-card>
          </v-row>
          <div class="titles">Documents</div>
          <v-divider></v-divider>
          <v-form ref="pform">
            <v-card
              outlined
              class="mb-8"
              :style="cardStyle"
              v-for="(document,
              k) in configuration.field_document_configurations"
              :key="'d_' + k"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Name"
                      required
                      outlined
                      background-color="#fff"
                      v-model="document.name"
                      :rules="[(v) => !!v || 'Document field name is required']"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <v-file-input
                      :label="document.document_id != null ? '' : 'Document'"
                      v-model="document.file"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon
                      outlined
                      background-color="#fff"
                    >
                      <template v-slot:label>
                        <span v-if="!document.document_id"> Select file </span>
                        <span
                          v-if="document.document_id && !document.file"
                          class="font-weight-bold"
                        >
                          <span
                            style="width: 70%; display: inline-block"
                            class="text-truncate"
                            >{{ document.uploaded_original_file_name }}</span
                          >
                          <span
                            style="width: 20%; display: inline-block"
                            class="text-truncate"
                            >.{{
                              document.uploaded_original_file_name.split(".")[
                                document.uploaded_original_file_name.split(".")
                                  .length - 1
                              ]
                            }}</span
                          >
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>

                  <v-col cols="12" sm="2" md="2">
                    <v-checkbox
                      v-model="document.is_visible"
                      label="View"
                      color="success"
                      value="document.is_visible"
                      v-bind:false-value="0"
                      v-bind:true-value="1"
                      hide-details
                      @change="
                        !document.is_visible ? (document.is_required = 0) : ''
                      "
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-checkbox
                      v-model="document.is_required"
                      label="Mandatory"
                      color="success"
                      value="document.is_required"
                      hide-details
                      v-bind:false-value="0"
                      v-bind:true-value="1"
                      @change="
                        document.is_required ? (document.is_visible = 1) : ''
                      "
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" sm="1" md="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="position: relative; top: calc(50% - 33px)"
                          v-if="
                            checkDeletePermission(
                              $modules.facility.management.slug
                            )
                          "
                          v-bind="attrs"
                          v-on="on"
                          color="red"
                          class="mt-2"
                          @click="deleteDocuments(k)"
                          fab
                          x-small
                          dark
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      Delete
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <div class="add_btn">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="teal-color"
                    fab
                    x-small
                    dark
                    @click="addDocuments"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                Add Document
              </v-tooltip>
            </div>
            <div class="text-base font-semibold black-text ml-1 mt-6">Booking Configurations</div>
            <v-card class="mb-8 rounded-2 bordered shadow-0 mt-2">
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <table class="table text-center table-bordered">
                      <thead>
                      <tr class="opacity-70 tr-neon tr-rounded text-center font-bold black-text">
                        <th class="text-center">Field Name</th>
                        <th class="text-center">View</th>
                        <th class="text-center">Mandatory</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="(config, index) in configuration.field_configurations"
                          :key="index"
                      >
                        <td class="black-text font-bold text-sm  " style="font-weight: 500 !important;">{{ config.name }}</td>
                        <td>
                          <v-btn
                              :style="
                          viewRestrictedFields.includes(config.slug) &&
                            'cursor:not-allowed;'
                        "
                              icon
                              @click="checkAsVisible(index)"
                          >
                            <TickIcon v-if="config.is_visible == 1 "/>
                            <CloseIcon v-else/>

                          </v-btn>
                        </td>
                        <td>
                          <v-btn
                              :style="
                          mandatoryRestrictedFields.includes(config.slug) &&
                            'cursor:not-allowed;'
                        "
                              icon
                              @click="checkAsMandatory(index)"
                          >
                            <TickIcon v-if="config.is_required == 1 "/>
                            <CloseIcon v-else/>

                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="blue-color" dark @click="close">Close</v-btn>
        <v-btn class="teal-color" @click="setConfiguration" dark>Save</v-btn>
      </v-card-actions>
    </v-card>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>

<script>
import TextEditor from "@/components/Marketing/TextEditor";
import TickIcon from "@/assets/images/misc/config-table-tick-icon.svg";
import CloseIcon from "@/assets/images/misc/config-table-close-icon.svg";
import constants from "@/utils/constants";
import GeneralMembership from "@/components/Membership/GeneralMembership.vue";
export default {
  props: {
    viewConfig: { type: Boolean, default: false },
  },
  components: {GeneralMembership, CloseIcon, TickIcon, TextEditor },
  data() {
    return {
      benefit_workshop: [],
      membershipForm: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      configuration: {
        tnc: "",
        tnc_enable: "0",
        field_document_configurations: [],
        deleted_field_documents: [],
        field_configurations: []
      },
      viewRestrictedFields: constants.VIEWRESTRICTEDFIELDS,
      mandatoryRestrictedFields: constants.MANTATORYRESTRICTEDFIELDS,
      tnc: "",
    };
  },
  watch: {
    viewConfig: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getConfiguration();
        }
      },
    },
  },
  methods: {
    updateFormWithMembership(data) {
      this.membershipForm = [];
      this.membershipForm = data;
      console.log(data,'data');
      this.$forceUpdate();
    },
    close() {
      this.$emit("close");
    },

    setTncContent(content) {
      this.tnc = content;
    },

    getConfiguration() {
      this.$http
        .get(`venues/workshops/configuration`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.configuration = data;
            if (this.configuration) {
              this.tnc = this.configuration.tnc;
            } else {
              this.configuration = {
                tnc: "",
                tnc_enable: "0",
                field_document_configurations: [],
              };
            }

            if (
              this.configuration.field_document_configurations &&
              this.configuration.field_document_configurations.length == 0
            ) {
              this.configuration.field_document_configurations.push({
                name: null,
                file: null,
                is_required: 0,
                is_visible: 0,
              });
            } else if (this.configuration.field_document_configurations) {
              this.configuration.field_document_configurations.forEach(
                (element) => {
                  if (!element.document_id) {
                    element.file = null;
                  }
                }
              );
            }

            this.configuration.deleted_field_documents = [];
            if (this.configuration && this.configuration.tnc_enable) {
              this.configuration.tnc_enable = this.configuration.tnc_enable.toString();
            }

            if(this.configuration.benefit_workshop){
              this.benefit_workshop = this.configuration.benefit_workshop;
            }


            if (this.$refs.pform) {
              this.$refs.pform.resetValidation();
            }
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    setConfiguration() {
      let formData = new FormData();
      formData.append(`tnc_enable`, this.configuration.tnc_enable);

      if (this.configuration.tnc_enable == 1) {
        formData.append(`tnc`, btoa(this.sanitizeHTML(this.tnc)));
      }
      if (this.configuration.field_document_configurations.length) {
        this.configuration.field_document_configurations.forEach(
          (item, index) => {
            if (item.name) {
              for (let key in item) {
                formData.append(
                  `field_document_configurations[${index}][${key}]`,
                  item[key]
                );
              }
            }
          }
        );
      }
      if (this.configuration.field_configurations.length) {
        this.configuration.field_configurations.forEach(
          (item, index) => {
            if (item.name) {
              for (let key in item) {
                formData.append(
                  `field_configurations[${index}][${key}]`,
                  item[key]
                );
              }
            }
          }
        );
      }
      if (this.configuration.deleted_field_documents.length) {
        this.configuration.deleted_field_documents.forEach((item, index) => {
          formData.append(`deleted_field_documents[${index}]`, item);
        });
      }

      if (this.membershipForm.data && this.membershipForm.data.length > 0) {

        formData.append(`membership_benefits_enable`, this.membershipForm.enable);

        this.membershipForm.data.forEach((ele, index) => {
          formData.append(`membership_benefits[${index}][membership_id]`, ele.membership_id);
          formData.append(`membership_benefits[${index}][membership_package_id]`, ele.membership_package_id);
          formData.append(`membership_benefits[${index}][membership_ids]`, ele.membership_ids);
          formData.append(`membership_benefits[${index}][membership_package_ids]`, ele.membership_package_ids);
          formData.append(`membership_benefits[${index}][benefit_type]`, ele.benefit_type);
          formData.append(`membership_benefits[${index}][benefit]`, ele.benefit);
          formData.append(`membership_benefits[${index}][benefit_id]`, ele.benefit_id);
          formData.append(`membership_benefits[${index}][id]`, ele.id);
          formData.append(`membership_benefits[${index}][workshop_membership_configuration_id]`, ele.workshop_membership_configuration_id);
        })

        this.membershipForm.deleted.forEach((ele, index) => {
          formData.append(`deleted_membership_benefits[${index}][membership_id]`, ele.membership_id);
          formData.append(`deleted_membership_benefits[${index}][membership_package_id]`, ele.membership_package_id);
          formData.append(`deleted_membership_benefits[${index}][benefit_type]`, ele.benefit_type);
          formData.append(`deleted_membership_benefits[${index}][benefit]`, ele.benefit);
          formData.append(`deleted_membership_benefits[${index}][benefit_id]`, ele.benefit_id);
          formData.append(`deleted_membership_benefits[${index}][id]`, ele.id);
        })
      }

      this.$http({
        method: "post",
        url: `venues/workshops/configuration`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Configuration saved");
            this.close();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addDocuments() {
      this.configuration.field_document_configurations.push({
        name: null,
        file: null,
        is_visible: 0,
        is_required: 0,
      });
    },

    deleteDocuments(index) {
      if (
        !this.configuration.field_document_configurations[index].name &&
        !this.configuration.field_document_configurations[index].id &&
        !this.configuration.field_document_configurations[index]
          .document_type_id
      ) {
        this.configuration.field_document_configurations.splice(index, 1);
        if (!this.configuration.field_document_configurations.length) {
          this.configuration.field_document_configurations = [{}];
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this custom field?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_document",
        };
      }
    },

    checkAsMandatory(index) {
      let field = this.configuration.field_configurations[index];
      if (!this.mandatoryRestrictedFields.includes(field.slug)) {
        this.configuration.field_configurations[index].is_required =
            field.is_required == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configuration.field_configurations[index].is_visible = 1;
        }
      }
    },
    checkAsVisible(index) {
      let field = this.configuration.field_configurations[index];
      if (!this.viewRestrictedFields.includes(field.slug)) {
        this.configuration.field_configurations[index].is_visible =
            field.is_visible == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configuration.field_configurations[index].is_required = 0;
        }
      }
    },
    confirmActions(data) {
      if (data.type == "delete_document") {
        if (
          this.configuration.field_document_configurations[data.id].id != null
        ) {
          this.configuration.deleted_field_documents.push(
            this.configuration.field_document_configurations[data.id].id
          );
        }
        this.configuration.field_document_configurations.splice(data.id, 1);
        if (!this.configuration.field_document_configurations.length) {
          this.configuration.field_document_configurations = [{}];
        }
      }
      this.confirmModel.id = null;
    },
  },
};
</script>

<style></style>
