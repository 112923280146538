<template>
  <v-dialog :value="viewConfig" width="1000" @input="close" scrollable>
    <v-card>
      <v-card-title class="border-bottom">
        <div class="w-full">
          <div class="d-flex justify-space-between align-center">
            <p class="mb-0 font-medium">
              Quick Scan Tags
            </p>
            <v-btn class="shadow-0" fab x-small @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-container>
          <v-row class="align-end">
            <v-col cols="12" lg="12" md="12">
              <v-form ref="cform">
                <v-row>
                  <v-col
                      lg="6"
                      md="6"
                      sm="12"
                      cols="12"
                      v-for="(code, cIndex) in colorCodes"
                      :key="`index${cIndex}`"
                      style="position: relative"
                  >
                    <v-card class="shadow-2">
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12" sm="12" class="pb-0">
                            <label for="">
                              Tag name
                            </label>
                            <v-text-field
                                outlined
                                background-color="#fff"
                                light
                                v-model="code.name"
                                dense
                                hide-details="auto"
                                class="q-text-field shadow-0"
                                required
                                :rules="[(v) => !!v || 'Tag name is required']"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" sm="12">
                            <label for="">
                              Color Code
                            </label>
                            <v-text-field
                                outlined
                                v-model="code.color_code"
                                background-color="#fff"
                                light
                                dense
                                hide-details="auto"
                                class="q-text-field shadow-0 color-picker"
                                required
                                :rules="[(v) => !!v || 'Color code is required']"
                            >
                              <template v-slot:append>
                                <v-menu
                                    top
                                    nudge-bottom="105"
                                    nudge-left="16"
                                    :close-on-content-click="false"
                                >
                                  <template v-slot:activator="{ on }">
                                    <div :style="swatchStyle(cIndex)" v-on="on"/>
                                  </template>
                                  <v-card>
                                    <v-card-text class="pa-0">
                                      <v-color-picker
                                          v-model="code.color_code"
                                          flat
                                          :swatches="swatches"
                                          show-swatches
                                      />
                                    </v-card-text>
                                  </v-card>
                                </v-menu>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col md="4" class="pl-0">
                            <v-switch
                                class="mx-4 mt-7"
                                dense
                                hide-details="auto"
                                :false-value="14"
                                :true-value="1"
                                label="Active"
                                required
                                v-model="code.status_id"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>


                    <v-tooltip bottom v-if="colorCodes.length > 1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="red"
                            dark
                            elevation="0"
                            fab
                            v-bind="attrs"
                            x-small
                            absolute
                            top
                            style="top:-5px;"
                            right
                            @click="deleteCode(cIndex)"
                            v-on="on"
                        >
                          <DeleteIcon/>
                        </v-btn>
                      </template>
                      Delete
                    </v-tooltip>
                  </v-col>
                </v-row>
                <div class="add_btn mt-4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="blue-color"
                          fab
                          x-small
                          dark
                          @click="addColorCode()"
                      >
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    Add new tag
                  </v-tooltip>
                </div>
              </v-form>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="blue-color" @click="setConfiguration" dark>Save</v-btn>
      </v-card-actions>
    </v-card>
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>


<script>
import DeleteIcon from "@/assets/images/retail/delete-bg-icon.svg";

export default {
  components: {DeleteIcon},
  props: {
    viewConfig: {type: Boolean, default: false},
  },
  watch: {
    viewConfig: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getConfiguration();
        }
      },
    },
  },
  data() {
    return {
      deleted_categories: [],
      colorCodes: [
        {
          name: null,
          status_id: 1,
          category_id: null,
          color_code: "#00AAAAFF",
        },
      ],
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      deleted_codes: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  methods: {
    addColorCode() {
      this.colorCodes.push({
        name: null,
        status_id: 1,
        category_id: null,
        color_code: "#00AAAAFF",
      });
      if (this.$refs.cform) {
        this.$refs.cform.resetValidation();
      }
    },
    swatchStyle(cIndex) {
      const {color_code, menu} = this.colorCodes[cIndex];
      return {
        backgroundColor: color_code,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    close() {
      this.$emit("close");
    },
    deleteCode(cIndex) {
      let pkg = this.colorCodes[cIndex];
      if (pkg.name == null && pkg.color_code == null && pkg.id == null) {
        this.colorCodes.splice(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this Tag?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
          type: "color_code",
        };
      }
    },
    getConfiguration() {
      this.$http
          .get(`venues/workshops/configuration/tags`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              if (data.length) {
                this.colorCodes = data;
              } else {
                this.colorCodes = [
                  {
                    name: null,
                    status_id: 1,
                    category_id: null,
                    color_code: "#00AAAAFF",
                  },
                ];
              }
              this.deleted_codes = [];
              this.$forceUpdate();
              if (this.$refs.cform) {
                this.$refs.cform.resetValidation();
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    setConfiguration() {
      if (!this.$refs.cform.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let data = {
        color_codes: this.colorCodes,
      };
      if (this.deleted_codes.length) {
        data.deleted_codes = this.deleted_codes;
      }
      this.showLoader();
      this.$http
          .post(`venues/workshops/configuration/tags`, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Tags saved");
              this.getConfiguration();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    confirmActions(data) {
      if (data.type === "color_code") {
        let index = data.id;
        if (this.colorCodes[index].id != null) {
          this.deleted_codes.push(this.colorCodes[index].id);
        }
        this.colorCodes.splice(index, 1);
        this.$forceUpdate();
      }
      this.confirmModel.id = null;
    },
  },
}
</script>
<style>
.color-picker .v-input__append-inner {
  margin-top: 6px !important;
}
</style>