<template>
  <v-container fluid no-gutters>
    <div class="row row--dense">
      <v-col cols="12" xl="12" lg="12">
        <div class="d-flex justify-space-between flex-wrap" >
          <div class="d-flex bordered qp-tab-nav ">
            <div class="nv_item">
              <v-autocomplete
                  :items="venueServices"
                  v-model="venueServiceIds"
                  item-value="venue_service_id"
                  item-text="name"
                  outlined
                  multiple
                  @change="changeVenueService"
                  placeholder="Services"
                  background-color="rgba(79, 174, 175, 0.1)"
                  hide-details
                  style="max-width: 150px;"
                  class="q-autocomplete shadow-0 nv_item_select q-tab-autocomplete"
                  :height="46"
                  dense
                  color="#4FAEAF"
                  item-color="#4FAEAF"

              >
              <template
            v-if="venueServiceIds.length == venueServices.length"
            v-slot:selection="{ index }"
          >
            <span v-if="index == 0">All Services</span>
          </template>
          <template v-else v-slot:selection="{ item, index }">
            <span v-if="index == 0">{{ item.name }}</span>
            <span v-if="index == 1">, {{ item.name }}</span>
            <span v-if="index == 2">, ...</span>
          </template>
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle()">
              <v-list-item-action>
                <v-icon
                  :color="venueServiceIds.length > 0 ? 'indigo darken-4' : ''"
                  >{{ icon() }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
              </v-autocomplete>
            </div>
              <div class="d-flex p-2">
                <template >
                  <div class="nv_item d-flex p-4 pointer" @click="gotoPage('/workshops')">
                    <SvgIcon class="text-xl qp-tab-nav-is-active" text="Academy" >
                      <template v-slot:icon>
                        <DashboardIcon />
                      </template>
                    </SvgIcon>
                  </div>
                  <v-divider
                      vertical style="background-color: rgba(220, 220, 220, 1)"
                  ></v-divider>
                </template>



                <template >
                  <div class="nv_item d-flex p-4 pointer" @click="gotoPage('/workshop-sales')" v-if="checkReadPermission($modules.workshops.sales.slug)">
                    <SvgIcon class="text-xl  text-thin-black " text="Sales" >
                      <template v-slot:icon>
                        <AnalyticsIcon />
                      </template>
                    </SvgIcon>
                  </div>

                </template>
              </div>
            </div>

          <div class="d-flex gap-x-2">

            <v-menu
                offset-y
                v-if="checkWritePermission($modules.workshops.management.slug)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    height="48"
                    class="white--text blue-color ml-1 conf"
                    color=" darken-1"
                    text
                >
                    <ConfigIcon />
                    <span class="ml-1"> Config</span>
                  <v-icon right dark>
                    mdi-menu-down
                  </v-icon></v-btn
                >
              </template>
              <v-list>
                <v-list-item @click="programLevelDialog = true">
                  <v-list-item-title>Color codes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openConfigModal">
                  <v-list-item-title>Fields</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openTagsModal">
                  <v-list-item-title>Quick Scan</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>


            <v-btn
                color=" darken-1"
                class="white--text blue-color ml-1"
                text
                height="48"
                v-if="checkWritePermission($modules.workshops.management.slug)"
              @click="addNewWorkshop"
            >
              <AddIcon/>
              <span class="ml-1">Add Academy</span>
            </v-btn>
          </div>
        </div>
      </v-col>
    </div>
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>


    <v-row dense class=mt-4>
      <v-col cols="8">
        <div class="d-flex ">
          <v-btn-toggle  class="mr-4 academy_schedule_days"   borderless v-model="allDaysSelected">
            <v-btn @click="selectAllDays" :value=true text :style="`${allDaysSelected?'border-color:#112A46 !important; background: rgba(17, 42, 70, 0.07) !important':' border:1px solid #EFEDED !important;'}`">
              All
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle multiple class="d-flex gap-x-4 academy_schedule_days "  v-model="selectedDays" borderless  @change="changeVenueService">
      <v-btn v-for="day in days" :key="day.value" :value="day.value" text  :style="`${selectedDays.includes(day.value)?'border-color:#112A46 !important; background: rgba(17, 42, 70, 0.07) !important':' border:1px solid #EFEDED !important;'}`">
        {{ day.label }}
      </v-btn>
    </v-btn-toggle>


        </div>
      </v-col>
      <v-col cols="4">
        <div class="d-flex justify-end">
          <v-select
                  v-model="toggleStatus"
                  :items="workshopStatuses"
                  class='q-autocomplete shadow-0'
                  dense
                  item-value="value"
                  item-text="text"
                  @change="changeVenueService()"
                  outlined
                  background-color="#fff"
                  style="max-width:160px !important"
                  hide-details
                  mandatory
                ></v-select>

        </div>
      </v-col>
    </v-row>

    <v-row>
      <template v-for="workshop in workshops">
        <v-col lg="4" md="6" xl="3" sm="12" :key="workshop.id" class="cos">
          <workshop-widget
            v-bind="workshop"
            :toggleStatus="toggleStatus"
            @duplicate="duplicateWorkshopCreate"
            @cancel="
              callCancelConfirm({
                id: workshop.id,
                type: workshop.status_id == 1 ? 'cancel' : 'reactivate',
              })
            "
          />
        </v-col>
      </template>
    </v-row>
    <v-pagination
      v-if="totalPages"
      v-model="page"
      :length="totalPages"
      class="new-pagination"

    ></v-pagination>
    <h3 class="text-center mt-12" v-if="workshops.length == 0">
      No {{ toggleStatus }} academies present
    </h3>
    <color-code
      @close="programLevelDialog = false"
      :viewColorCode="programLevelDialog"
      :productType="'academy'"
    ></color-code>
    <workshop-configuration
      @close="workshopConfigDialog = false"
      :viewConfig="workshopConfigDialog"
    ></workshop-configuration>
    <workshop-tags
      @close="workshopTagsDialog = false"
      :viewConfig="workshopTagsDialog"
    ></workshop-tags>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirm"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>
<script>
import WorkshopWidget from "@/components/Workshop/WorkshopWidget";
import ColorCode from "@/components/Settings/ColorCode";
import WorkshopConfiguration from "./WorkshopConfiguration.vue";
import WorkshopTags from "./WorkshopTags.vue";
import DashboardIcon from "@/assets/images/memberships/dashboard-icon.svg";
import AnalyticsIcon from "@/assets/images/memberships/analytics-up.svg";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import ConfigIcon from "@/assets/images/misc/cog_icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {

  data() {
    return {
      toggleStatus: 'active',
      venueServiceIds: [],
      workshops: [],
      totalPages: 1,
      page: 1,
      programLevelDialog: false,
      workshopConfigDialog: false,
      workshopTagsDialog: false,
      btnShow: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },

      workshopStatuses: [
        {text: "Upcoming", value: "active"}, {text: "Completed", value: "completed"}, {
          text: "Pending",
          value: "pending"
        },
        {text: "Cancelled", value: "cancelled"}
      ],
      selectedDays: [

      ],
      days: [
        {label: 'Sun', value: 'Sunday'},
        {label: 'Mon', value: 'Monday'},
        {label: 'Tue', value: 'Tuesday'},
        {label: 'Wed', value: 'Wednesday'},
        {label: 'Thu', value: 'Thursday'},
        {label: 'Fri', value: 'Friday'},
        {label: 'Sat', value: 'Saturday'}
      ],
      allDaysSelected : false,
    };
  },
  watch: {
    page() {
      this.loadWorkshops();
    },
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter(
        (service) => service.name != "POS"
      );
    },
  },
  components: {
    WorkshopWidget,
    ColorCode,
    WorkshopConfiguration,
    WorkshopTags,
    SvgIcon,
    AddIcon,
    DashboardIcon,AnalyticsIcon,ConfigIcon
  },
  mounted() {
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then(() => {
        this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
        );
        this.loadWorkshops()
      });
    }else{

      this.venueServiceIds = this.venueServices.map(
          (item) => item.venue_service_id
      );
      this.loadWorkshops();
    }
  },
  methods: {
    gotoPage(route){
      this.$router.push(route);
    },

    selectAllDays() {
      const dayValues = this.days.filter(day => day.value !== 'all').map(day => day.value);
      if(!this.allDaysSelected){
        this.selectedDays = dayValues;
      }else{
        this.selectedDays= [];
      }
      this.changeVenueService()
      console.log(dayValues , this.allDaysSelected)
    },
    openConfigModal() {
      this.workshopConfigDialog = true;
      this.$forceUpdate();
    },
    openTagsModal() {
      this.workshopTagsDialog = true;
      this.$forceUpdate();
    },
    loadWorkshops() {
      this.showLoader("Loading..");
      let url = this.venueServiceIds
        .map((item, index) => `&venue_service_ids[${index}]=${item}`)
        .join(",");
      let days = this.selectedDays
          .map((item, index) => `&days[${index}]=${item}`);
      this.$http
        .get(
          `venues/workshops?page=${this.page}&per_page=12&status=${this.toggleStatus}${url}${days}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.workshops = response.data.data;
            this.totalPages = response.data.total_pages;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.venueServices.length == this.venueServiceIds.length) {
          this.venueServiceIds = [];
        } else {
          this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
          );
          this.$forceUpdate;
        }
      });
      setTimeout(() => {
        this.page = 1;
        this.loadWorkshops();
      });
    },
    icon() {
      if (this.venueServices.length == this.venueServiceIds.length)
        return "mdi-close-box";
      if (this.venueServiceIds.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    addNewWorkshop() {
      this.$router.push(
        {
          name: "WorkshopAdd",
        },
        () => {}
      );
    },

    callCancelConfirm(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${data.type} this academy?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
        type: "academy",
        data: {
          id: data.id,
          type: data.type,
        },
      };
    },

    confirm(data) {
      if (data.type == "academy") {
        this.callCancelWorkshop(data);
      }
      if (data.type == "duplicate") {
        this.duplicateWorkshop(data.id);
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    callCancelWorkshop(data) {
      this.$http
        .put(`venues/workshops/${data.id}/status`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.confirmModel.id = null;
            this.loadWorkshops();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    duplicateWorkshopCreate(data) {
      this.confirmModel = {
        id: data,
        title: `Do you want to create duplicate of this academy?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
        type: "duplicate",
      };
    },

    duplicateWorkshop(id) {
      this.showLoader("Duplicate academy creating...");
      this.$http
        .put(`venues/workshops/duplicate/${id}`)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess(response.data.message);
            this.loadWorkshops();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changeVenueService(){
      if(this.page != 1){
        this.page = 1;
      }else{
        this.loadWorkshops();
      }
    }
  },
};
</script>
<style scoped>


.date_button_navigation button {
  width: 98px;
}
.ptBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.masterBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn {
  min-width: 150px;
}

.conf{
  path{
    fill: #F2F2F2 !important;
  }
}

</style>
